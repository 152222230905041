import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import '@/firebase/'


// import './plugins/chartist'
// import './plugins/vee-validate'
// import i18n from './i18n'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  //  i18n,
  render: h => h(App)
}).$mount('#app')
