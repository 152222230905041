import Vue from 'vue'
import Vuex from 'vuex'
import projects from './modules/projects'
import users from './modules/users'
import timeReports from './modules/timeReports'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    projects,
    users,
    timeReports
  },
  state: {
    token: null,
    drawer: null,
    editedTimeReport: {
      month: new Date().toISOString().substr(0, 7),
      project: null,
      hours: 0,
      type: "Normal",
      vacationDates: [],
      sickDays: [],
      sickHoursFirstDay: 0,
      parentalLeaveExtent: 100,
      parentalDays: [],
      vabItems: []
    },
    dialogReport: {
      monthReport: false,
      vacationReport: false,
      sickReport: false,
      parentalReport: false,
      childCareReport: false
    },
    reportDayBreak: 10,
    editedIndex: -1,
    organizationID: "qZgaaRkpL6O4fSBfS9HO"
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_DIALOG_MONTH(state, payload) {
      state.dialogReport.monthReport = payload
    },
    SET_DIALOG_SICK(state, payload) {
      state.dialogReport.sickReport = payload
    },
    SET_DIALOG_VACATION(state, payload) {
      state.dialogReport.vacationReport = payload
    },
    SET_DIALOG_PARENTAL(state, payload) {
      state.dialogReport.parentalReport = payload
    },
    SET_DIALOG_CHILD_CARE(state, payload) {
      state.dialogReport.childCareReport = payload
    },
    SET_EDITED_TIME_REPORT(state, payload) {
      state.editedTimeReport = payload
    },
    SET_EDITED_INDEX(state, payload) {
      state.editedIndex = payload
    },

  },
  actions: {
    SET_DIALOG_MONTH({ commit }, payload) {
      commit('SET_DIALOG_MONTH', payload)
    },
    SET_DIALOG_SICK({ commit }, payload) {
      commit('SET_DIALOG_SICK', payload)
    },
    SET_DIALOG_VACATION({ commit }, payload) {
      commit('SET_DIALOG_VACATION', payload)
    },
    SET_DIALOG_PARENTAL({ commit }, payload) {
      commit('SET_DIALOG_PARENTAL', payload)
    },
    SET_DIALOG_CHILD_CARE({ commit }, payload) {
      commit('SET_DIALOG_CHILD_CARE', payload)
    },
    SET_EDITED_TIME_REPORT({ commit }, payload) {
      commit('SET_EDITED_TIME_REPORT', payload)
    },
    SET_EDITED_INDEX({ commit }, payload) {
      commit('SET_EDITED_INDEX', payload)
    },
    LOG_OUT_USER({ commit }) {
      commit('setGoogleUser', null)
    },
  },
  getters: {
    dialogMonth(state) {
      return state.dialogReport.monthReport
    },
    dialogSick(state) {
      return state.dialogReport.sickReport
    },
    dialogVacation(state) {
      return state.dialogReport.vacationReport
    },
    dialogParental(state) {
      return state.dialogReport.parentalReport
    },
    dialogChildCare(state) {
      return state.dialogReport.childCareReport
    },
    editedTimeReport(state) {
      return state.editedTimeReport
    },
    editedIndex(state) {
      return state.editedIndex
    }
  }
})