import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import colors from 'vuetify/lib/util/colors'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
    /*
    Darker: #457664

    Main/ Primary: #64a48c

    Lighter/Secendery: #94c0b0
        
    Use White and black
    */
    theme: {
        themes: {
            light: {
                primary: "#64a48c", 
                secondary: "#94c0b0", 
                darker: "#457664",
                accent: colors.blue.accent1, 
                cancel: "#d34935",
                lightBack: "#eff5f3"
            },
            dark: {
                primary: "#64a48c", 
                secondary: "#94c0b0", 
                darker: "#457664",
                accent: colors.blue.accent1, 
                cancel: "#d34935",
                lightBack: "#eff5f3"
            }
        },
    },
})