<template>
  <router-view />
</template>

<script>
window.LOG_LEVEL = "INFO";
export default {
  name: "App",
};
</script>
