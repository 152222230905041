import * as fb from '@/firebase'
import { v4 as uuidv4 } from 'uuid'

const state = () => ({
  timeReports: [],
  userTimeReports: []
})

const getters = {
  timeReports(state) {
    return state.timeReports
  },
  userTimeReports(state) {
    return state.userTimeReports
  },
}

const actions = {
  async fetchUserTimeReports({ commit }, user) {
    const userDocRef = fb.userCollection.doc(user.id)

    let userObj = await fb.userCollection.doc(user.id)
      .get()
      .then((u) => {
        return { id: u.id, data: u.data() }
      })

    let timeReports = []

    let snapshots = await fb.timeReportsCollection.where('user', '==', userDocRef).get()
    if (!snapshots.empty) {
      await snapshots.forEach(async doc => {
        let tr = await createTimeReportFromDoc(doc, userObj)
        timeReports.push(tr)
      })
    }

    commit('setUserTimeReports', timeReports)
  },
  //TODO: TimeReports should be tied to org, need to be updated
  async fetchTimeReports({ commit }) {
    try {
      var snapshot = await fb.timeReportsCollection.get()
    } catch (error) {
      console.log('Could not fetch project got error: ', error)
    }

    let timeReports = []

    await snapshot.forEach(async doc => {
      let user = await fb.userCollection.doc(doc.data().user.id)
        .get()
        .then((u) => {
          return { id: u.id, data: u.data() }
        })
      let tr = await createTimeReportFromDoc(doc, user)

      timeReports.push(tr)
    })

    commit('setTimeReports', timeReports)
  },

  //TODO add parms for parental leave
  async addUserTimeReport({ commit }, { item, user }) {
    const id = uuidv4()
    let tr = {
      id: id,
      hours: item.hours,
      vacationDates: item.vacationDates,
      date: `${item.month}`,
      type: item.type,
      user: fb.userCollection.doc(user.id),
      sickDays: item.sickDays,
      sickHoursFirstDay: item.sickHoursFirstDay,
      parentalDays: item.parentalDays,
      parentalLeaveExtent: item.parentalLeaveExtent,
      vabItems: item.vabItems
    }

    // For timereports without proj do not add a proj ref
    if (item.project !== null) {
      tr.project = fb.projectCollection.doc(item.project.id)
    }

    fb.timeReportsCollection.doc(id).set(tr
    ).then(() => {
      console.log("Document successfully written!");
      item.id = id
      commit('addUserTimeReport', item)
    }).catch(function (error) {
      console.error("Error writing document: ", error);
    })
  },
  async updateUserTimeReport({ commit }, { item: item, user: user }) {
    let proj = null
    if (typeof item.project !== 'undefined' && item.project !== null) {
      proj = item.project.id
    }

    updateTimeReport(
      item.id,
      user.id,
      proj,
      item.hours,
      item.month,
      item.type,
      item.vacationDates,
      item.sickDays,
      item.sickHoursFirstDay,
      item.parentalDays,
      item.parentalLeaveExtent,
      item.vabItems
    ).then(() => {
      console.log("TimeReport updated!")
      commit('updateUserTimeReport', { item })
    }).catch((e) => {
      console.error("Could not update time report due t: ", e)
    })

  },
  async deleteUserTimeReport({ commit }, item) {
    fb.timeReportsCollection.doc(item.id).delete()
      .then(() => {
        commit('removeUserTimeReport', item)
        console.log("TimeReport deleted!")
      })
      .catch((e) => {
        console.log("Error removing time Report: ", e)
      })
  },
}

const mutations = {
  setTimeReports(state, payload) {
    state.timeReports = payload
  },
  setUserTimeReports(state, payload) {
    state.userTimeReports = payload
  },
  addUserTimeReport(state, payload) {
    state.userTimeReports.push(payload)
  },
  removeUserTimeReport(state, payload) {
    const index = state.userTimeReports.findIndex(x => x.id === payload.id)
    state.userTimeReports.splice(index, 1)
  },
  updateUserTimeReport(state, payload) {
    const index = state.userTimeReports.findIndex(x => x.id === payload.item.id)
    state.userTimeReports.splice(index, 1, payload.item)
  },
}
// Add params for updating parental leave
async function updateTimeReport(id, userId = "", projectId = "",
  hours = 0, month = "", type = "Normal", vacationDates = [],
  sickDays = [], sickHoursFirstDay = 0,
  parentalDays = [], parentalLeaveExtent = 100,
  vabItems = []) {
  let trRef = fb.timeReportsCollection.doc(id)
  var projRef = null
  if (projectId !== null) {
    projRef = fb.projectCollection.doc(projectId)
  }

  if (userId !== "") {
    var userRef = fb.userCollection.doc(userId)
  } else {
    throw "Missing userID"
  }

  let tr = {
    id: id,
    user: userRef,
    project: projRef,
    hours: hours,
    date: month,
    type: type,
    vacationDates: vacationDates,
    sickDays: sickDays,
    sickHoursFirstDay: sickHoursFirstDay,
    parentalDays: parentalDays,
    parentalLeaveExtent: parentalLeaveExtent,
    vabItems: vabItems
  }

  if (projRef !== null) {
    tr.project = projRef
  }
  await trRef.update(tr)
}

function createTimeReport(id = uuidv4(), month = "", hours = 0,
  user, project, type = "Normal",
  vacationDates = [],
  sickDays = [], sickHoursFirstDay = 0,
  parentalDays = [], parentalLeaveExtent = 100,
  vabItems = []) {
  return {
    id,
    month,
    hours,
    user,
    project,
    type,
    vacationDates,
    sickDays,
    sickHoursFirstDay,
    parentalDays,
    parentalLeaveExtent,
    vabItems
  }
}
// Create a timereport from a timereport firebase doc 
async function createTimeReportFromDoc(doc, user) {
  let project = await getProject(doc.data().project)
  // Add Type into timeReport
  let type = "Normal"
  if (typeof doc.data().type === 'undefined') {
    if (doc.data().project !== null && typeof project.data !== 'undefined') {
      type = await getTypeFromProject(project.data.name)
    }
  } else {
    type = doc.data().type
  }
  let tr = createTimeReport(
    doc.data().id,
    doc.data().date,
    doc.data().hours,
    user,
    project,
    type,
    (typeof doc.data().vacationDates !== 'undefined' ? doc.data().vacationDates : []),
    (typeof doc.data().sickDays !== 'undefined' ? doc.data().sickDays : []),
    (typeof doc.data().sickHoursFirstDay !== 'undefined' ? doc.data().sickHoursFirstDay : 0),
    (typeof doc.data().parentalDays !== 'undefined' ? doc.data().parentalDays : []),
    (typeof doc.data().parentalLeaveExtent !== 'undefined' ? doc.data().parentalLeaveExtent : 100),
    (typeof doc.data().vabItems !== 'undefined' ? doc.data().vabItems : [])
  )
  return tr

}

async function getProject(projDoc) {
  if (typeof projDoc !== 'undefined' && projDoc !== null) {
    var project = await fb.projectCollection.doc(projDoc.id)
      .get()
      .then((p) => {
        return { id: p.id, data: p.data() }
      })
  } else {
    return null
  }
  return project
}


function getTypeFromProject(projectName) {
  if (["Vacation", "Sickness", "VAB", "Parental leave"].includes(projectName)) {
    return projectName
  } else {
    return "Normal"
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}