import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
// import store from '@/store'

var firebaseConfig = {
    "projectId": "timereportidp",
    "appId": "1:407769309775:web:c17faf7add338310abc03a",
    "storageBucket": "timereportidp.appspot.com",
    "locationId": "europe-west2",
    "apiKey": "AIzaSyCDQyaWwJ2gbG9yoRFDuzViqxcO0pdOw-k",
    "authDomain": "timereportidp.firebaseapp.com",
    "messagingSenderId": "407769309775"
}

firebase.initializeApp(firebaseConfig)

// Util
const db = firebase.firestore()
if (location.hostname === "localhost") {
    db.useEmulator("localhost", 8085)
}

const auth = firebase.auth()

if (location.hostname === "localhost") {
    firebase.auth().useEmulator('http://localhost:9099/')
}

// Add Auth persistence
// auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
// 
// firebase.auth().onAuthStateChanged(user => {
//     if (user != null) {
//         store.dispatch("users/fetchUser", user.email);
//     }
// });

// Collection references
const userCollection = db.collection('users')
const projectCollection = db.collection('projects')
const timeReportsCollection = db.collection('timeReports')
const organizationsCollections = db.collection('organizations')

var getUserVacationCollection = (uid) => {
    return userCollection.doc(uid).collection('vacationDates')
}

export {
    db,
    auth,
    userCollection,
    projectCollection,
    timeReportsCollection,
    organizationsCollections,
    getUserVacationCollection
}