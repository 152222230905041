import * as fb from '@/firebase'

// import db from '../../firebase/repo'

import firebase from 'firebase/app'
import router from '@/router'

const state = () => ({
  displayName: "",
  isAuthenticated: false,
  token: null,
  users: [],
  googleUser: null,
  user: {
    "active": true,
    "email": "",
    "rule": "",
    "name": ""
  },
  projects: null,
})

const getters = {
  token(state) {
    return state.token
  },
  users(state) {
    return state.users
  },
  user(state) {
    return state.user
  },
  projects(state) {
    return state.projects
  },
}

const actions = {
  async fetchUsers({ commit, rootState }, orgID) {
    let users = []
    const orgRef = fb.organizationsCollections.doc(orgID)
    fb.userCollection.where("organization", "==", orgRef)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async function (doc) {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          let currentDate = new Date(Date.now())
          let year = currentDate.getFullYear()
          // Add one due to firebase date string is 1 - 12 and not 0 - 11
          let month = currentDate.getMonth() + 1
          // If it at the start of the month, reported should be pointing to if the user have reported in previous month
          if (currentDate.getDate() <= rootState.reportDayBreak) {
            month = month - 1
          }

          // If the month is zero this should point to last month last year
          if (month == 0) {
            month = 12
            year = year - 1
          }

          const monthstr = ('0' + month).slice(-2)
          const monthString = year + "-" + monthstr

          let query = fb.timeReportsCollection
            .where("date", "==", monthString)
            .where("user", "==", fb.userCollection.doc(doc.data().id))

          let qs = await query.get()
          let reported = "No"
          if (qs.size > 0) {
            reported = "Yes"
          }
          // const reported = rootGetters.reported(doc.data().id, monthString)
          let user = {
            name: doc.data().name,
            email: doc.data().email,
            id: doc.data().id,
            rule: doc.data().rule,
            reported: reported
          }
          users.push(user)
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error)
      });

    commit('setUsers', users)

  },
  async fetchUser({ dispatch, commit, rootState }, uid) {
    await fb.userCollection.doc(uid).get()
      .then(async (doc) => {
        if (doc.exists) {
          console.log("User document found at:", doc.ref.path)
          commit('setUser', doc.data())
          dispatch('timeReports/fetchUserTimeReports', doc.data(), { root: true })
          dispatch('fetchUserProjects', doc.data())
          if (doc.data().rule == "admin") {
            await dispatch("projects/fetchProjects", {}, { root: true });
            await dispatch("fetchUsers", rootState.organizationID);
            await dispatch("timeReports/fetchTimeReports", {}, { root: true })
          }
        } else {
          console.error("No user found")
        }
      })
      .catch((err) => {
        console.error("Error getting user document, got error: ", err)
      })
  },
  async fetchUserProjects({ commit }, user) {
    async function getUserProjects(userID) {
      const userDocRef = fb.userCollection.doc(userID)
      let projs = await fb.projectCollection.where('user', '==', userDocRef)
        .get()
        .then((querySnapshot) => {
          let projects = []
          querySnapshot.forEach(function (doc) {
            //console.log(doc.id, " => ", doc.data())
            projects.push({ id: doc.id, data: doc.data() })
          })
          return projects
          //commit('setUserProjects', projects)
        })
        .catch((e) => {
          console.log("Error getting doc: ", e)
        })
      return projs
    }

    let projects = await getUserProjects(user.id)
    let allUserProjects = await getUserProjects("all")
    projects.push(...allUserProjects)

    commit('setUserProjects', projects)

  },
  async userLogin({ dispatch, commit }) {
    var provider = new firebase.auth.GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/plus.login')
    provider.setCustomParameters({
      hd: 'zoolengineering.se',
    })
    //firebase.auth().signInWithRedirect(provider)
    firebase.auth().signInWithPopup(provider)
      .then(function (result) {
        // Validate if user exist in db
        commit('setGoogleUser', result.user)
        dispatch('fetchUser', result.user.uid)
        router.push('/').catch((e) => {
          console.error(e)
        })
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        commit('setGoogleUser', null)
        router.push('/login')
      })
  },
  async userSignOut({ commit }) {
    firebase.auth().signOut()
      .then(function () {
        commit('setGoogleUser', null)
        router.push('/login')
      })
      .catch(function (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        commit('setGoogleUser', null)
        router.push('/login')
      })
  },
}

const mutations = {
  setGoogleUser(state, payload) {
    state.googleUser = payload
  },
  setUser(state, payload) {
    state.user = payload
  },
  setToken(state, payload) {
    state.token = payload
  },
  setUserProjects(state, payload) {
    state.projects = payload
  },
  setUsers(state, payload) {
    state.users = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

