import Vue from 'vue';
import Router from 'vue-router';
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      children: [
        {
          path: '',
          name: 'Time Report',
          component: () => import('@/views/TimeReport'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/admin/users',
          name: 'Users',
          component: () => import('@/views/Admin/Users'),
          meta: {
            requiresAuth: true,
            requiresAdmin: true
          }
        },
        {
          path: '/admin/projects',
          name: 'Projects',
          component: () => import('@/views/Admin/Projects'),
          meta: {
            requiresAuth: true,
            requiresAdmin: true
          }
        },
        {
          path: '/admin/time',
          name: 'Time',
          component: () => import('@/views/Admin/Time'),
          meta: {
            requiresAuth: true,
            requiresAdmin: true
          }
        },
        {
          path: '/admin/report',
          name: 'Report',
          component: () => import('@/views/Admin/Report'),
          meta: {
            requiresAuth: true,
            requiresAdmin: true
          }
        },
        {
          path: '/time-report',
          name: 'Time Report',
          component: () => import('@/views/TimeReport'),
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/missing-permissions',
          name: 'Missing permissions',
          component: () => import('@/views/MissingPermissions'),
          meta: {
            requiresAuth: true,
          }
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login')
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.users.googleUser) {
      next({
        path: '/login',
      })
    }
  }
  if (to.matched.some(record => record.meta.requiresAdmin)){
    if (store.state.users.user.rule != "admin") {
      next({
        path: '/missing-permissions'
      })
    }    
  }

  next()
})

export default router
