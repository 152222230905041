import * as fb from '@/firebase'

import { v4 as uuidv4 } from 'uuid'

const state = () => ({
    projects: []
})

const getters = {
    projects(state) {
        return state.projects
    }
}

const actions = {
    // TODO: Project request project for a organization
    async fetchProjects({ commit }) {
        let projects = []
        fb.projectCollection.get()
            .then((querySnapshot) => {
                querySnapshot.forEach(async function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    var project = {
                        name: doc.data().name,
                        poNumber: doc.data().poNumber,
                        id: doc.data().id,
                        customer: doc.data().customer,
                    }

                    if (doc.data().user) {
                        let user = await fb.userCollection.doc(doc.data().user.id)
                            .get()
                            .then((u) => {
                                user = u.data()
                                return user
                            })
                            .catch((e) => {
                                console.log("Could not find user for project: ", e)
                            })
                        project.user = user
                    }
                    projects.push(project)
                });
                commit('setProjects', projects)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    },
    async addProject({ commit }, item) {
        const id = uuidv4()
        await fb.projectCollection.doc(id).set({
            id: id,
            name: item.name,
            poNumber: item.poNumber,
            // TODO: Zool org number, should come from state
            organization: fb.organizationsCollections.doc("qZgaaRkpL6O4fSBfS9HO"),
            user: fb.userCollection.doc(item.user.id),
            customer: item.customer
        }).then(() => {
            console.log("Document successfully written!");
            item.id = id
            commit('addProject', item)
        }).catch((e) => {
            console.log("Error writing document: ", e)
        })
    },
    async deleteProject({ commit }, item) {
        await fb.projectCollection.doc(item.id).delete()
            .then(() => {
                commit('removeProject', item)
                console.log("TimeReport deleted!")
            })
            .catch((e) => {
                console.log("Error removing time Report: ", e)
            })
    },
    async editProject({ commit }, { item, index }) {
        let organizationRef = fb.organizationsCollections.doc("qZgaaRkpL9O4fSBfS9HO")
        let userRef = fb.userCollection.doc(item.user.id)
        let projRef = fb.projectCollection.doc(item.id)
        return await projRef.update({
            id: item.id,
            name: item.name,
            poNumber: item.poNumber,
            organization: organizationRef,
            user: userRef,
            customer: item.customer
        })
            .then(() => {
                console.log("Project updated!")
                commit('updateProject', { item, index })
            })
            .catch((e) => {
                console.error("Could not update project: ", e)
            })
    },

}

const mutations = {
    setProjects(state, payload) {
        state.projects = payload
    },
    removeProject(state, payload) {
        const index = state.projects.findIndex(x => x.id === payload.id)
        state.projects.splice(index, 1)
    },
    addProject(state, payload) {
        state.projects.push(payload)
    },
    updateProject(state, payload) {
        state.projects.splice(payload.index, 1, payload.item)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

